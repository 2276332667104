import React from "react"
import { graphql } from "gatsby"
import Container from "../components/container"

export default function Blog({ data }) {
  const { posts } = data.blog

  return (
    <Container>
        <div>
        <div className="text-4xl">My blog posts</div>

        {posts.map(post => (
            <article key={post.id}>
            <h2>{post.frontmatter.title}</h2>
            <small>{post.frontmatter.author}, {post.frontmatter.date}</small>
            <p>{post.excerpt}</p>
            </article>
        ))}
        </div>
    </Container>
  )
}

export const blogQuery = graphql`
  query blogQuery {
    blog: allMarkdownRemark {
      posts: nodes {
        frontmatter {
          date(fromNow: true)
          title
          author
        }
        excerpt
        id
      }
    }
  }`